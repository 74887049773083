import React from "react";

import Modal from "../../../components/Modals";

import { RiFileList3Line } from "react-icons/ri";
import TabsForm from "./steps/TabsForm";
const ModalRegister = ({ actionModal, openModal, isEditMode, data = undefined }) => {
    return (
        <div>
            <Modal
                overflow="overflow-y-hidden"
                onClose={actionModal}
                size="xs:w-11/12 lg:w-11/12 min-h-90%"
                show={openModal}
                title={
                    <div className="flex gap-2 items-center">
                        <span className="text-roxo_oficial font-bold text-xl">
                            <RiFileList3Line />
                        </span>
                        <h5 className="mr-2 text-roxo_oficial font-bold">
                            {isEditMode ? "Edição" : "Cadastro"} de novo contrato
                        </h5>
                    </div>
                }>
                <TabsForm data={data} />
            </Modal>
        </div>
    );
};

export default ModalRegister;
